import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import blogs from "./blogs.json";

interface Post {
  title: string;
  metaDescription: string;
  main: string;
  image: string;
  slug: string;
}

const Blog: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithState = (post: Post) => {
    navigate(`/blogs/${post.slug}`, { state: { post } });
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header data={location.pathname} />
      <Box flexGrow={1}>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={5}
          py={5}
          sx={{ backgroundColor: "#1D232F", minHeight: "65.5vh" }}
        >
          {blogs.map((post, index) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={index}
              onClick={() => navigateWithState(post)}
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Card
                sx={{ maxWidth: 350, height: "400px", borderRadius: "20px" }}
              >
                <CardMedia
                  component="img"
                  sx={{ height: "280px" }}
                  image={post.image}
                  alt="placeholder"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {post.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: "#1D232F", cursor: "pointer" }}
                  >
                    Continue Reading...
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default Blog;
