import React from "react";

function Footer() {
  return (
    <div className="container-fluid background-container">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-6 d-flex justify-content-start footer-logo">
            <p className="label-font mt-5">
              <img
                src="/assets/images/navlogo.png"
                alt="logo"
                width="202px"
                height="43px"
              />
              <p className="nav-header">
                Textky.com is your key to texting success !
              </p>
              <div className="row">
                <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 mx-1 d-flex justify-content-start">
                  <a
                    href="https://www.facebook.com/profile.php?id=61550646200281"
                    target="_blank"
                  >
                    <img
                      src="/assets/images/facebook.png"
                      alt=""
                      width="35px"
                      height="35px"
                    />
                  </a>
                </div>
                <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2  mx-1  d-flex justify-content-start">
                  <a href="https://twitter.com/Textkyy" target="_blank">
                    <img
                      src="/assets/images/twitter.png"
                      alt=""
                      width="35px"
                      height="35px"
                    />
                  </a>
                </div>
                <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2  mx-1 d-flex justify-content-start">
                  <a
                    href="https://www.linkedin.com/company/textky"
                    target="_blank"
                  >
                    <img
                      src="/assets/images/linkdln.png"
                      alt=""
                      width="35px"
                      height="35px"
                    />
                  </a>
                </div>
                <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 mx-1 d-flex justify-content-start">
                  <a href="https://www.instagram.com/textky1/" target="_blank">
                    <img
                      src="/assets/images/instagram.png"
                      alt=""
                      width="35px"
                      height="35px"
                    />
                  </a>
                </div>
              </div>
            </p>
          </div>

          <div className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-6 d-flex justify-content-end contact contact-us">
            <ul className="label-font">
              <p className="fw-bold label-font about">Contact US</p>
              <b>Textky.com</b>
              <br />
              <b>Charlotte NC 28226</b>
              <br />
              <b>
                <a
                  className="footer-decoration label-font"
                  href="mailto:info@textky.com"
                >
                  info@textky.com
                </a>
              </b>

              <br />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
