import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useRef } from "react";

function HomePage() {
  const myRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div className="container-fluid background-container">
        {/* <---------------Start Header component ------------> */}
        <Header myRef={myRef} />
        {/* <---------------End Header component ------------> */}

        {/* <---------------Start Main Content ------------> */}

        <div className="background-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8">
              <p className="main-content justify-content-center">
                Text Message Platform for your Business.
              </p>
              <h1 className="main-content2 justify-content-center">
                Send text messages to <br /> your contacts in one
                <br /> click
              </h1>
              <div className="row justify-content-center mt-5 mb-5 gap-3">
                <button
                  type="button"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_BOOK_DEMO_URL}`,
                      "_blank"
                    )
                  }
                  className="content-button btn btn-outline"
                >
                  Book a Demo
                </button>
                <button
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_ADMIN_PORTAL_URL}`,
                      "_blank"
                    )
                  }
                  className="content-button"
                  type="button"
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-5 carousel-responsive">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="assets/images/message.png" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="assets/images/message2.png" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="assets/images/message3.png" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container card-background-container" ref={myRef}>
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center">
            <div className="card card-property text-center">
              <div className="card-body">
                <h5 className="card-title">
                  <img
                    src="assets/images/setting.png"
                    alt="settings"
                    width="65px"
                    height="65px"
                  />
                  <hr />
                </h5>
                <p className="card-content">
                  Automate your bulk SMS campaign with our advanced flow system.
                  Faster, better and easier way to business texting. Reach your
                  target audience seamlessly and efficiently using our automated
                  SMS flow.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center">
            <div className="card card-property card-property text-center">
              <div className="card-body">
                <h5 className="card-title">
                  <img
                    src="assets/images/chatGPT.png"
                    alt="chatGPT"
                    width="65px"
                    height="65px"
                  />
                  <hr />
                </h5>
                <p className="card-content">
                  Looking for a hassle-free way to personlise your messages? Our
                  platform makes it easy for you to create eye catching
                  campaigns with the help of ChatGPT to communicate effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center">
            <div className="card card-property text-center">
              <div className="card-body">
                <h5 className="card-title">
                  <img
                    src="assets/images/people.png"
                    alt="chatGPT"
                    width="65px"
                    height="65px"
                  />
                  <hr />
                </h5>
                <p className="card-content">
                  Send your SMS marketing messages through Textky.com to
                  increase engagement. Everything you need to connect with a
                  targeted audience in fun and meaningful ways
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center">
            <div className="card card-property text-center">
              <div className="card-body">
                <h5 className="card-title">
                  <img
                    src="assets/images/analysis.png"
                    alt="analysis"
                    width="65px"
                    height="65px"
                  />
                  <hr />
                </h5>
                <p className="card-content">
                  Say goodbye to ineffective text marketing and hello to a more
                  profitable future with Textky.com. SMS marketing tool that
                  ensure your messages are delivered to the right people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid background-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center">
              <div>
                <video className="video-content" controls>
                  <source src="https://d1mqififsdsrfl.cloudfront.net/assets/videos/Textky.com.mp4"></source>
                </video>
                <div className="d-flex justify-content-center video-label">
                  <h4 className="video-bottom-label">See how it works</h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 video-text">
              <div className="video-text-content justify-content-center">
                <p>
                  A Faster, better and easier way to <br /> business texting
                </p>
              </div>
              <div className="video-text-content2 justify-content-center">
                <p>
                  Send or schedule text messages to a large number of <br />{" "}
                  leads and customers at once and handle responses <br /> from
                  one centralized place.
                </p>
              </div>
              <div className="row justify-content-center mb-5 gap-3">
                <button
                  type="button"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_BOOK_DEMO_URL}`,
                      "_blank"
                    )
                  }
                  className="content-button btn btn-outline"
                >
                  Book a Demo
                </button>
                <button
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_ADMIN_PORTAL_URL}`,
                      "_blank"
                    )
                  }
                  className="content-button"
                  type="button"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid background-container-quotes">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h1 className="quotes-content d-flex justify-content-center">
                What our clients
                <br /> has to say about <br />
                us
              </h1>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div className="quote-content-text d-flex justify-content-center">
                <b>
                  <img
                    src="assets/images/start.png"
                    className="mx-3 quote-space"
                    alt="start"
                    width="40px"
                    height="40px"
                  />
                  As a small team, simplifying our <br /> workflow is a top
                  priority, Through
                  <br /> TextKy having our contacts, SMS, and
                  <br /> email in one place helps us a ton!
                  <img
                    src="assets/images/stop.png"
                    className="mx-3 quote-space"
                    alt="stop"
                    width="40px"
                    height="40px"
                  />
                </b>
              </div>

              <div className="row about mb-5">
                <div className="col-3 d-flex justify-content-end">
                  <img
                    src="assets/images/thumb.png"
                    alt="thumb"
                    width="55px"
                    height="55px"
                  />
                </div>
                <div className="col-9 d-flex justify-content-start">
                  <div className="mt-1">
                    <b>Jerry Cruz </b>
                    <br />
                    <p>Elementor, Marketing Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <---------------End Main Content ------------> */}

      {/* <---------------Start Footer Component ------------> */}

      <Footer />

      {/* <---------------End Footer Component ------------> */}
    </>
  );
}

export default HomePage;
