import "./App.css";
import HomePage from "./app/pages/Main/Homepage";
import BlogPage from "./app/pages/Blogs/BlogPage";
import BlogDetails from "./app/pages/Blogs/BlogDetails";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
