import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import Box from "@mui/material/Box";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
interface Post {
  id: number;
  title: string;
  metaDescription: string;
  main: string;
  image: string;
}

const BlogDetails: React.FC = () => {
  const location = useLocation();
  const post: Post | undefined = location.state?.post;

  if (!post) {
    return <div>Error: Post not found</div>;
  }

  return (
    <>
      <Header data={location.pathname} />
      <Box className="d-flex justify-content-center" p={2} /* padding of 2 */>
        <Box className="col-xs-3 col-sm-6 col-md-6 col-lg-6">
          <h1 className="my-5">{post.title}</h1>
          <img
            className="mb-5"
            src={post.image}
            alt={post.image}
            style={{ maxWidth: "100%", objectFit: "cover" }}
          />
          {/* <p>{post.metaDescription}</p> */}
          <div dangerouslySetInnerHTML={{ __html: post.main }} />
        </Box>
      </Box>
      <hr />
      <Footer />
    </>
  );
};

export default BlogDetails;
