import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

function Header(props: any) {
  const { myRef } = props;
  const handleClick: any = () => {
    myRef.current?.scrollIntoView();
  };
  const location = useLocation();
  const isBlogsRoute = location.pathname === "/blogs";
  const isblogDetails = location.pathname === "/blogs/blog-details";

  return (
    <>
      <div className="background-container">
        <nav className="navbar navbar-expand-lg">
          <div className="container d-flex justify-content-center">
            <p className="navbar-brand label-font mt-3">
              <Link to="/">
                <img
                  src="/assets/images/navlogo.png"
                  width="202px"
                  height="43px"
                />
              </Link>
              <p className="nav-header">
                Textky.com is your key to texting success !
              </p>
            </p>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item"></li>
                <li className="nav-item"></li>
                <li className="nav-item"></li>
              </ul>
              <form className="d-flex justify-content-center mb-3">
                {isBlogsRoute ? null : (
                  <>
                    <Link
                      to="/blogs"
                      className="nav-label mx-3 textDecoration: none"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Blogs
                    </Link>
                  </>
                )}
                {isblogDetails || isBlogsRoute ? null : (
                  <>
                    <a
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <label
                        className="nav-label mx-3"
                        style={{ cursor: "pointer" }}
                      >
                        Why TextKy
                      </label>
                    </a>
                  </>
                )}
                <a href={process.env.REACT_APP_BOOK_DEMO_URL} target="_blank">
                  <label
                    className="nav-label mx-3"
                    style={{ cursor: "pointer" }}
                  >
                    Book a Demo
                  </label>
                </a>
                <a
                  href={process.env.REACT_APP_ADMIN_PORTAL_URL}
                  target="_blank"
                >
                  <button
                    type="button"
                    className="nav-button btn btn-outline mx-2"
                  >
                    Log In
                  </button>
                </a>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;
